import { Distribution_EN, Distribution_FR } from "../categories/Distriution";
import { PartOfaWhole_EN, PartOfaWhole_FR } from "../categories/Composition";
import { Relationship_EN, Relationship_FR } from "../categories/Correlation";
import { Trend_FR, Trend_EN } from "../categories/Tendance";

export const CATEGORIES_EN = [
    Distribution_EN,
    PartOfaWhole_EN,
    Relationship_EN ,
    Trend_EN,
    
  ];


  export const CATEGORIES_FR = [
    Distribution_FR,
    PartOfaWhole_FR,
    Relationship_FR ,
    Trend_FR,
  ];