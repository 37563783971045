const desc = [{type: 'text', content: `<p>Le donut plot permet de représenter la proportion de modalités d'une variable. </p>`}]
const ex = [{type: 'text', content: `<h4>Exemples de cas d'utilisation</h4><ul><li>Pourcentage de femmes et d'hommes appelant le 3114 </li>
</ul> <h4>Exemple de graphique</h4>`}]
const tip=[{type:'text', content: `<ul><li> Préférer représenter les proportions sous forme de pourcentage plutôt qu'en effectifs absolus, cela permet de mieux se représenter la part que prend la modalité dans le tout. </li></ul> `}]
export const Donutplot= {
    id: "graph8",
    title: "Donut plot",
    description: desc,
    exemple: ex,
    tips: tip,
    img_intro:'' ,
    img_desc: '',
    fonction: ` `,
  }