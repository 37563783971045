import histogramImg from "../assets/images/graph1.png";
import boxplotImg from "../assets/images/boxplot/boxplot.png";
import violinplotImg from "../assets/images/graph1.png";
import densityplotImg from "../assets/images/densityPlot/densityplot.png";
import ridgelineImg from "../assets/images/graph1.png";
export const Distribution_EN = {
  id: "cat1",
  title: "Distribution",
  description: `<p>Les courbes de distribution sont particulièrement utiles pour analyser la centralité, la dispersion et la forme des données. Elles permettent de répondre aux questions suivantes :
    <ul>
    <li>Centralité des données :
    <ul>
    <li>Où se situe la valeur centrale des données&nbsp;?
    </li>
    <li>Quelle est la valeur la plus fréquente&nbsp;?</li>
    <li>Les données sont-elles symétriques par rapport à leur moyenne&nbsp;?</li>
    </ul>
    </li>
    <li>Dispersion des données :
    <ul>
    <li>À quel point les données sont-elles étalées ou concentrées&nbsp;?</li>
    <li>Quelle est l'étendue (différence entre la valeur maximale et minimale) des données&nbsp;?</li>
    <li>Comment se répartissent les données autour de la moyenne (écart-type)&nbsp;?</li>
    <li>Y a-t-il des observations extrêmement éloignées de la plupart des données (valeurs aberrantes)&nbsp;?</li>
    </ul>
    </li>
    <li>Forme de la distribution :
    <ul>
    <li>La distribution des données suit-elle une forme particulière&nbsp;?</li>
    <li>Les données présentent-elles des valeurs aberrantes ou des asymétries&nbsp;? </li>
    <li>Les données suivent-elles les conditions requises pour des tests statistiques particuliers&nbsp;? </li>
    </ul>
    
    </li>
    <li>Comparaison de groupes ou de distributions :
    <ul>
    <li>Comment les distributions de plusieurs groupes se comparent-elles entre elles&nbsp;? </li>
    <li>Les distributions de deux groupes sont-elles statistiquement différentes ?</li>
    </ul>
    </li>
    
    </ul>
    
    </p>`,
  img: histogramImg,
  imgs: [
    {
      src: histogramImg,
      alt: "Histogram",
      title: "Histogram",
      graphId: "graph10",
    },
    { src: boxplotImg, alt: "Box plot", title: "Box plot", graphId: "graph3" },
    {
      src: violinplotImg,
      alt: "Violin plot",
      title: "Violin plot",
      graphId: "graph16",
    },
    {
      src: densityplotImg,
      alt: "Density plot",
      title: "Density plot",
      graphId: "graph6",
    },
    {
      src: ridgelineImg,
      alt: "Density Ridgeline plot",
      title: "Density Ridgeline plot",
      graphId: "graph11",
    },
  ],
  graphs: ["graph10", "graph3", "graph16", "graph6", "graph11"],
};

export const Distribution_FR = {
  id: "cat1",
  title: "Distribution",
  description: `<p>Les courbes de distribution sont particulièrement utiles pour analyser la centralité, la dispersion et la forme des données. Elles permettent de répondre aux questions suivantes :
    <ul>
    <li>Centralité des données :
    <ul>
    <li>Où se situe la valeur centrale des données&nbsp;?
    </li>
    <li>Quelle est la valeur la plus fréquente&nbsp;?</li>
    <li>Les données sont-elles symétriques par rapport à leur moyenne&nbsp;?</li>
    </ul>
    </li>
    <li>Dispersion des données :
    <ul>
    <li>À quel point les données sont-elles étalées ou concentrées&nbsp;?</li>
    <li>Quelle est l'étendue (différence entre la valeur maximale et minimale) des données&nbsp;?</li>
    <li>Comment se répartissent les données autour de la moyenne (écart-type)&nbsp;?</li>
    <li>Y a-t-il des observations extrêmement éloignées de la plupart des données (valeurs aberrantes)&nbsp;?</li>
    </ul>
    </li>
    <li>Forme de la distribution :
    <ul>
    <li>La distribution des données suit-elle une forme particulière&nbsp;?</li>
    <li>Les données présentent-elles des valeurs aberrantes ou des asymétries&nbsp;? </li>
    <li>Les données suivent-elles les conditions requises pour des tests statistiques particuliers&nbsp;? </li>
    </ul>
    
    </li>
    <li>Comparaison de groupes ou de distributions :
    <ul>
    <li>Comment les distributions de plusieurs groupes se comparent-elles entre elles&nbsp;? </li>
    <li>Les distributions de deux groupes sont-elles statistiquement différentes ?</li>
    </ul>
    </li>
    
    </ul>
    
    </p>`,
  img: histogramImg,
  imgs: [
    {
      src: histogramImg,
      alt: "Histogram",
      title: "Histogram",
      graphId: "graph10",
    },
    { src: boxplotImg, alt: "Box plot", title: "Box plot", graphId: "graph3" },
    {
      src: violinplotImg,
      alt: "Violin plot",
      title: "Violin plot",
      graphId: "graph16",
    },
    {
      src: densityplotImg,
      alt: "Density plot",
      title: "Density plot",
      graphId: "graph6",
    },
    {
      src: ridgelineImg,
      alt: "Density Ridgeline plot",
      title: "Density Ridgeline plot",
      graphId: "graph11",
    },
  ],
  graphs: ["graph10", "graph3", "graph16", "graph6", "graph11"],
};
