const desc = [{type: 'text', content: `<p>Un scatterplot permet de représenter la relation entre deux variables numériques. Chaque point correspond à une observation.
</p>`}]
const ex = [{type: 'text', content: `<h4>Exemples de cas d'utilisation</h4> <ul><li>Représenter la relation entre le poids et la taille</li></ul><h4>Exemple de graphique</h4>`}]
const tip=[{type:'text', content: `<ul><li>Relier les points suggère une continuité, une évolution. Il est préférable de relier les points quand l'échelle des abscisses est continue, sinon laisser les points non reliés.  </li>
<li>S'il y a trop de points, le scatterplot peut devenir rapidement illisible. Dans ce cas il peut être préférable de diviser la variable des abscisses en classes, afin de transformer la variable quantitative en variable qualitative ordinale, et de représenter en ordonnée la médiane/quartiles : faire un boxplot/violin plot</li> </ul> `}]
export const Scatterplot= {
    id: "graph12",
    title: "Scatterplot",
    description: desc,
    exemple: ex,
    tips: tip,
    img_intro:'' ,
    img_desc: '',
    fonction: ` `,
  }