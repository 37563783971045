const desc = [{type: 'text', content: `<p>Le stream plot est un type d'area plot. <br/><br/> A Stream graph is a type of [stacked area chart](https://www.data-to-viz.com/graph/stackedarea.html). It displays the evolution of a numeric value (Y axis) following another numeric value (X axis). This evolution is represented for several groups, all with a distinct color.
<br/><br/> Contrary to a stacked area, there is no corner: edges are rounded what gives this nice impression of flow. Moreover, areas are usually displaced around a central axis, resulting in a flowing and organic shape.
</p>`}]
const ex = []
const tip=[]
export const Streamplot= {
    id: "graph13",
    title: "Stream plot",
    description: desc,
    exemple: ex,
    tips: tip,
    img_intro:'' ,
    img_desc: '',
    fonction: ``,
  }