import graph5desc from "../assets/images/correlogram/correlogram_interpr.png";
import graph5intro from "../assets/images/correlogram/correlogram.png";
const desc = [
  {
    type: "text",
    content: `<p>Le correlogramme ou matrice de corrélation permet d'analyser la relation entre toutes les paires de variables quantitatives des données fournies. Le but du correlogramme est plus exploratoire qu'explicatif. </p>`,
  },
  { type: "img", content: graph5desc, alt:'Graph Correlogramme'},
  {
    type: "text",
    content: `<p>Une case correspond à la valeur de corrélation entre deux variables. <br/> <br/> Une valeur de corrélation proche de 1 indique une forte corrélation positive. Lorsque l'une des deux valeurs augmente, l'autre augmente aussi. Si elle diminue, l'autre diminue. Et vice-versa. Exemple : L'association entre var1 et var2 est positive, les deux variables sont fortement corrélées positivement car 0.83 est proche de 1. Donc quand var1 augmente, var2 augmente.<br/> <br/>Plus la valeur de corrélation s'approche de 0, moins les deux valeurs sont corrélées. L'évolution de l'une n'influe pas ou peu sur l'évolution de l'autre. Exemple : var5 et var8 sont très peu corrélés. L'évolution de l'un influe peu sur l'évolution de l'autre. <br/> <br/>Une valeur de corrélation proche de -1 indique une forte corrélation négative. Lorsque l'une des deux valeurs augmente, l'autre diminue. Et vice-versa. Exemple : var7 et var3 ont une forte corrélation négative, car -0.85 est proche de -1. Quand var7 augmente, var3 diminue. `,
  },
];
const ex = [
  {
    type: "text",
    content: `<h4>Exemples de cas d'utilisation</h4><ul><li> Etudier les associations de comorbidités</li>
</ul> <h4>Exemple de graphique</h4>`,
  },
];
const tip=[]
export const Correlogram = {
  id: "graph5",
  title: "Correlogram",
  description: desc,
  exemple: ex,
  tips: tip,
  img_intro: graph5intro,
  img_desc: "",
  fonction: ` correlogram(data, type = "lower", lab_size = 3,
    colors = c("red", "white", "green"), title = "",
    round = 2)`,
};
