import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import HomePage from "./pages/Home";
import RootElement from "./pages/Root";
import ErrorPage from "./pages/Error";
// import CategorieDetails from "./pages/CategorieDetails";
// import CategorieMoreDetailsPage from "./pages/CategorieMoreDetails";
// import GraphPage from "./pages/GraphPage";
// import GraphCategoriesPage from "./pages/GraphCategories";
import { lazy, Suspense } from "react";

const CategorieDetails = lazy(() => import("./pages/CategorieDetails"));
const GraphPage = lazy(() => import("./pages/GraphPage"));
const GraphCategoriesPage = lazy(() => import("./pages/GraphCategories"));
const CategorieMoreDetailsPage = lazy(() =>
  import("./pages/CategorieMoreDetails")
);
const router = createBrowserRouter([
  {
    path: "/",
    element: <RootElement />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <HomePage />,
        children: [
          {
            path: "categories",
            children: [
              { index: true },
              { path: ":catId", element:  <Suspense fallback={<p>Loading...</p>}><CategorieDetails /></Suspense> },
            ],
          },
        ],
      },
      {
        path: "categories/:catId/more",
        element: (
          <Suspense fallback={<p>Loading...</p>}>
            
            <CategorieMoreDetailsPage />
          </Suspense>
        ),
      },
      {
        path: "graphs/:graphId",
        element: (
          <Suspense fallback={<p>Loading...</p>}>
            <GraphCategoriesPage />
          </Suspense>
        ),
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<p>Loading...</p>}>
                <GraphPage />
              </Suspense>
            ),
          },
        ],
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
