import DonutplotImg from "../assets/images/graph1.png";

export const PartOfaWhole_EN = {
    id: "cat2",
    title: "Composition",
    description:`<p>Les graphiques de composition permettent de comparer des effectifs au sein d'un ensemble. Ils permettent de répondre aux questions suivantes : 
  <ul>
  <li>Répartition des groupes : 
  <ul>
  <li>Est-ce que la population est répartie équitablement entre les groupes ?
  </li>
    </ul>
  </li>
  <li>Évolution dans le temps : 
  <ul>
  <li>Est-ce que la répartition de la population entre les groupes évolue dans le temps ?</li>
  </ul>
  </li>
  
   </ul>
    </p>`,
    img: DonutplotImg,
    imgs: [{src: DonutplotImg, alt:'Donut plot', title:'Donut plot', graphId: "graph8" }],
    graphs: ["graph8"]
  }


  export const PartOfaWhole_FR = {
    id: "cat2",
    title: "Composition",
    description:
    `<p>Les graphiques de composition permettent de comparer des effectifs au sein d'un ensemble. Ils permettent de répondre aux questions suivantes : 
    <ul>
    <li>Répartition des groupes : 
    <ul>
    <li>Est-ce que la population est répartie équitablement entre les groupes ?
    </li>
      </ul>
    </li>
    <li>Évolution dans le temps : 
    <ul>
    <li>Est-ce que la répartition de la population entre les groupes évolue dans le temps ?</li>
    </ul>
    </li>
    
     </ul>
      </p>`,
          img: DonutplotImg,
    imgs: [{src: DonutplotImg, alt:'Donut plot', title:'Donut plot', graphId: "graph8" }],
    graphs: ["graph8"]
  }