import { Areaplot } from "../graphs/Areaplot";
import { Barplot } from "../graphs/Barplot";
import { Boxplot } from "../graphs/Boxplot";
import { Bubbleplot } from "../graphs/Bubbleplot";
import { Correlogram } from "../graphs/Correlogram";
import { Densityplot } from "../graphs/Densityplot";
import { DivergingBar } from "../graphs/DivergingBar";
import { Donutplot } from "../graphs/Donutplot";
import { Heatmap } from "../graphs/Heatmap";
import { Histogram } from "../graphs/Histogram";
import { RidgeLine } from "../graphs/RidgeLine";
import { Scatterplot } from "../graphs/Scatterplot";
import { Streamplot } from "../graphs/Streamplot";
import { Upsetplot } from "../graphs/Upsetplot";
import { VennDiagram } from "../graphs/VennDiagram";
import { Violinplot } from "../graphs/Violinplot";
export const GRAPHS_FR = [Areaplot, Barplot, Boxplot, Bubbleplot, Correlogram, Densityplot,DivergingBar, Donutplot,Heatmap, Histogram, RidgeLine, Scatterplot,Streamplot, Upsetplot, VennDiagram, Violinplot];
