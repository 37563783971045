import Classes from "./CategorieComp.module.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { styled } from "styled-components";

const Row = styled.div`
  &::after {
    content: "";
    clear: both;
    display: table;
     }
  padding:0.5rem 0rem;
`;
const CatTitle=styled.h2`
display:block;
background-color: var(--thirdColor);
display: block;
color: var(--primaryColor);
text-align: center;
padding: 0.8rem;
margin: 0;
@media only screen and (min-width: 768px) {
  display:none;
}
`
const Column = styled.div`
  float:  none;
  width:  ${(props) => (props.spansm ? (props.spansm / 12) * 100 : "100")}%;
  box-sizing: border-box;
  text-align: center;
  margin:0 auto;
  @media only screen and (min-width: 768px) {
    float:left;
    width: ${(props) => (props.span ? (props.span / 12) * 100 : "8.33")}%;
    box-sizing: border-box;
  }
`;
const GraphImg = styled.img`
  border-radius: 50%;
  width:80%;
  opacity:0.7;
  transition: all 0.2s ease-in;
  &:hover {
    border-radius: 10%;
    opacity:1;
  }
`;
const CatDesc = styled.div`
  color: var(--secondaryColor);
  padding: 0.5rem;
  text-align: left;
`;
const ImgTitle=styled.p`
  color: var(--secondaryColor);
  margin-top: 0px;
  font-size:0.9rem;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: block;
  opacity:0.8;
  &:hover {
    opacity:1;
  }
`
export default function CategorieComp({ cat }) {
  const CATEGORIES = useSelector((state) => state.lang.categories);
  const lang = useSelector((state) => state.lang.language);


  let content = {};
  if (lang === "en") {
    content = { title1: "Example", buttonContent: "More About" };
  }
  if (lang === "fr") {
    content = { title1: "Exemple", buttonContent: "En détails..." };
  }

  let categorie = cat;

  if (!cat) {
    categorie = CATEGORIES[0];
  }
  

  var size = 3;
  var arrayOf4Imgs = [];
  for (var i = 0; i < categorie.imgs.length; i += size) {
    arrayOf4Imgs.push(categorie.imgs.slice(i, i + size));
  }

  return (
    
    <>
   
    <CatTitle> {categorie.title}</CatTitle>
      <Row>
      
        <Column span="7">
        
          <CatDesc className={Classes.desc}
          

             dangerouslySetInnerHTML={{ __html: categorie.description }}
          />
        </Column>
        <Column span="5" >
          <div className={Classes.webview}>
            {arrayOf4Imgs.map((singleArray) => {
              return (
                <Row key={arrayOf4Imgs.indexOf(singleArray)}>
                  {" "}
                  <Column span="12" key={`Column${arrayOf4Imgs.indexOf(singleArray)}`}>
                  <Row>
                    {singleArray.map((imge) => (
                      
                        <Column span="4" spansm="6" key={`Column${singleArray.indexOf(imge)}`} style={{
                          float: singleArray.length> 1 && singleArray.length === 3  ? 'left': 'none',
                          display: singleArray.length=== 1 || singleArray.length < 3  ? 'inline-block' : 'block'
                        }}>
                        <Link key={`Link${arrayOf4Imgs.indexOf(singleArray)}-${singleArray.indexOf(imge)}`} to={`/graphs/${imge.graphId}`}>
                          <GraphImg src={imge.src} alt={imge.alt} />
                          <ImgTitle>{imge.title} </ImgTitle>
                          </Link>
                        </Column>
                      
                    ))}
                    </Row>
                  </Column>
                </Row>
              );
            })}
          </div>

          <div className={Classes.mobileView}>
            {categorie.imgs.map((imge) => {
              return (
                <Column span="4" spansm="4" key={`Column${categorie.imgs.indexOf(imge)}`} style={{
                          float: 'none',
                          display:  'inline-block'
                        }}>
                        <Link key={`Link${categorie.imgs.indexOf(imge)}-${categorie.imgs.indexOf(imge)}`} to={`/graphs/${imge.graphId}`}>
                          <GraphImg src={imge.src} alt={imge.alt} />
                          <ImgTitle>{imge.title} </ImgTitle>
                          </Link>
                        </Column>                      
                        );
            })}
          </div>
          
        </Column>
      </Row>

      <div className={Classes.actonsDiv}>
        <Link
          to={`/categories/${categorie.id}/more`}
          className={Classes.button}
        >
         {categorie.title} {content.buttonContent} 
        </Link>
      </div>
    </>
  );
}
