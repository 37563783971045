import React, { useState } from "react";
import { NavLink, Link, Outlet } from "react-router-dom";
import Classes from "./Root.module.css";
import logo from "../assets/images/logo2.png";
import { langActions } from "../store/index";
import { useDispatch, useSelector } from "react-redux";
import FR from "../assets/images/france.png";
import EN from "../assets/images/english.png";

export default function RootElement() {
  const lang = useSelector((state) => state.lang.language);
  const categories = useSelector((state) => state.lang.categories);
  const [showDrop, setShowDrop] = useState(false);
  const [showUl, setShowUl] = useState(false);
  const dispatch = useDispatch();
  const frHandler = () => {
    dispatch(langActions.changeToFr());
  };
  const enHandler = () => {
    dispatch(langActions.changeToEn());
  };
  const toggleUl = () => {
    setShowUl(!showUl);
  };
  const frClass = lang === "fr" ? `langbutton ${Classes.active}` : `langbutton`;
  const enClass = lang === "en" ? `langbutton ${Classes.active}` : `langbutton`;

  return (
    <>
      <nav className={Classes.nav} onClick={()=>{if(showUl) setShowUl(false)}}>
        <div className={Classes.container}>
          <Link to="/" key="homeLinklogo">
            <img src={logo} alt="logo" />
          </Link>
          <ul className={Classes.navul}>
            <li key="homeLinkLi">
              <NavLink
                key="homeLink"
                to="/"
                className={({ isActive }) =>
                  isActive ? Classes.active : undefined
                }
                onMouseEnter={() => setShowDrop(false)}
              >
                {" "}
                Home{" "}
              </NavLink>
            </li>
            <li
              key="categoriesLinkLi"
              className={
                !showDrop
                  ? Classes.dropdown
                  : `${Classes.dropdown} ${Classes.open}`
              }
              onMouseEnter={() => setShowDrop(true)}
            >
              <NavLink
                key="categoriesLink"
                to="categories"
                className={({ isActive }) =>
                  isActive ? Classes.active : undefined
                }
              >
                Categories
              </NavLink>
              {showDrop && (
                <ul
                  key="categoriesListUlTitle"
                  onMouseLeave={() => setShowDrop(false)}
                >
                  {categories.map((cat) => (
                    <li key={`categoriesListLi ${categories.indexOf(cat)}`}>
                      <NavLink
                        to={`categories/${cat.id}/more`}
                        className={({ isActive }) =>
                          isActive ? Classes.active : undefined
                        }
                      >
                        {cat.title}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          </ul>
        </div>

        <div className={Classes.containerMob}>
          <Link to="/" key="homeLinklogo">
            <img src={logo} alt="logo" />
          </Link>
          {showUl && (
            <ul className={Classes.navul}>
              <li key="homeLinkLi" onClick={toggleUl}>
                <NavLink
                  key="homeLink"
                  to="/"
                  className={({ isActive }) =>
                    isActive ? Classes.active : undefined
                  }
                >
                  Home
                </NavLink>
              </li>
              <li key="categoriesLinkLi" onClick={toggleUl}>
                <NavLink
                  key="categoriesLink"
                  to="categories"
                  className={({ isActive }) =>
                    isActive ? Classes.active : undefined
                  }
                >
                  Categories
                </NavLink>
              </li>
              {categories.map((cat) => (
                <li
                  className={Classes.dropLi}
                  key={`categoriesListLi ${categories.indexOf(cat)}`}
                  onClick={toggleUl}
                >
                  <NavLink
                    to={`categories/${cat.id}/more`}
                    className={({ isActive }) =>
                      isActive ? Classes.active : undefined
                    }
                  >
                    {cat.title}
                  </NavLink>
                </li>
              ))}
            </ul>
          )}
        </div>
        <div
          className={
            !showUl
              ? Classes.buttonContainer
              : `${Classes.buttonContainer} ${Classes.change}`
          }
          onClick={toggleUl}
        >
          <div className={Classes.bar1}></div>
          <div className={Classes.bar2}></div>
          <div className={Classes.bar3}></div>
        </div>
        <div className={Classes.langdiv}>
          <button className={frClass} onClick={frHandler}>
            <img src={FR} alt="Fr frlag"></img>
          </button>

          <button className={enClass} onClick={enHandler}>
            <img src={EN} alt="En frlag"></img>
          </button>
        </div>
      </nav>

      <main
        onClick={() => {
          setShowUl(false);
        }}
      >
        <Outlet />
      </main>
    </>
  );
}
