
const desc = [{type: 'text', content: `<p>Le bubbleplot est un scatterplot avec une troisième variable numérique d'incorporée. La troisième variable est alors représentée sous forme de diamètre du point
</p>`}]
const ex = [{type: 'text', content: `<h4>Exemples de cas d'utilisation</h4><ul><li>Poids en fonction de la taille, le diamètre du point représentant la taille de l'échantillon (? exemple très nul) </li>
</ul> <h4>Exemple de graphique</h4>`}]
const tip=[{type:'text', content: `<ul><li>Tout comme le scatterplot, si il y a trop d'observations l graphique devient illisible.</li></ul> `}]
export const Bubbleplot= {
    id: "graph4",
    title: "Bubbleplot",
    description: desc,
    exemple: ex,
    tips: tip,
    img_intro:'' ,
    img_desc: '',
    fonction: ` `,
  }