const desc = [{type: 'text', content: `<p>L'upset plot permet de visualiser les effectifs de plusieurs groupes. Une observation peut appartenir à un groupe, ou à plusieurs. 
<br/><br/>
Ce diagramme a une visée exploratoire. C'est une variante du diagramme de Venn particulièrement indiquée lorsqu'il y a plus de 3 groupes et leurs intersections à représenter.
<br/><br/>
Les points représentent la catégorie d'observation décrite (appartient à un groupe, ou deux, ou trois...). Un point seul représente les observations n'appartenant qu'à un groupe, deux points ou plus reliés représentent une association entre deux ou plusieurs groupes. Les labels des groupes sont visibles à gauche, avec la taille complète du groupe (observations appartenant uniquement à ce groupe + observations appartenant à ce groupe et à d'autres). 
<br/><br/>
=> Comparaison Upset plot VS Diagramme de venn
</p>`}]
const ex = [{type: 'text', content: `<h4>Exemples de cas d'utilisation</h4> <ul><li>Association de diagnostics (Anxiété, Depression, Schizophrénie par ex ?)</li></ul><h4>Exemple de graphique</h4>`}]
const tip=[{type:'text', content: `<ul><li> S'il y a 3 modalités ou moins à représenter, le <a href='/graphs/graph15'> diagramme de venn</a> est préféré </li> </ul> `}]
export const Upsetplot= {
    id: "graph14",
    title: "Upset plot",
    description: desc,
    exemple: ex,
    tips: tip,
    img_intro:'' ,
    img_desc: '',
    fonction: ` `,
  }