const desc = [{type: 'text', content: `<p>La heatmap permet d'explorer la relation entre les modalités de deux variables. Le but de la heatmap, tout comme le correlogramme est plus à visée exploratoire qu'explicative
</p>`}]
const ex = [{type: 'text', content: `<h4>Exemples de cas d'utilisation</h4> <h4>Exemple de graphique</h4>`}]
const tip=[{type:'text', content: `<ul><li> Si on veut explorer la relation deux à deux de toutes les variables quantitatives disponibles, il faut utiliser le correlogramme. </li> </ul> `}]
export const Heatmap= {
    id: "graph9",
    title: "Heatmap",
    description: desc,
    exemple: ex,
    tips: tip,
    img_intro:'' ,
    img_desc: '',
    fonction: ` `,
  }