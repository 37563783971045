import { createSlice } from "@reduxjs/toolkit";
import { configureStore } from "@reduxjs/toolkit";
import { GRAPHS_FR } from "./Graphs_arr";
import { CATEGORIES_EN, CATEGORIES_FR } from "./Categories_variables";

const langSlice = createSlice({
  name: "lang",
  initialState: {
    language: "fr",
    categories: CATEGORIES_EN,
    graphs: GRAPHS_FR,
  },
  reducers: {
    changeToFr(state, action) {
      state.categories = CATEGORIES_FR;
      state.language = "fr";
    },
    changeToEn(state, action) {
      state.categories = CATEGORIES_EN;
      state.language = "en";
    },
  },
});
const store = configureStore({
  reducer: { lang: langSlice.reducer },
});

export default store;
export const langActions = langSlice.actions;
