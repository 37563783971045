import graph1intro from "../assets/images/areaPlot/areaplot.png";
import graph1desc from "../assets/images/areaPlot/area_interpretation.png";
const desc = [
  {
    type: "text",
    content: `<p>L'area plot permet de visualiser l'évolution d'une variable quantitative. Plusieurs modalités d'une variable quantitative peuvent être représentées en même temps.</p> `,
  },
  { type: "img", content: graph1desc, alt: 'Graph Area plot' },
];
const ex = [
  {
    type: "text",
    content: `<h4>Exemples de cas d'utilisation</h4><ul><li>Evolution du taux d'occupation des lits au fil des mois selon les services. </li>
<li>Evolution du nombre de personnes hospitalisées par année par pathologie.</li></ul><h4>Exemple de graphique</h4>`,
  },
];
const tip = [
  {
    type: "text",
    content: `<ul><li>Graphique à utiliser pour regarder l'apparition ou la disparition de modalités, ou pour observer un renversement de tendance évident, mais pas pour de la quantification relative stricte.</li></ul>`,
  },
];
export const Areaplot = {
  id: "graph1",
  title: "Area Plot",
  description: desc,
  exemple: ex,
  tips: tip,
  img_intro: graph1intro,
  img_desc: graph1desc,
  fonction: `stacked_area(df, x, y, group = NULL, alpha = 0.5, color = NULL, title = "",
subtitle = "", axe_x = "", axe_y = "", fill_title = "", 
caption = "") `,
};
