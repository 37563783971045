import RootElement from "./Root";
import Classes from './Error.module.css'
export default function ErrorPage(){
    return (<>
        <RootElement />
        <main className={Classes.main}>
        <h1>An error Occured</h1>
        <p> Page not found</p>
        </main>
        </>)
}