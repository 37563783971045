import areaplotImg from "../assets/images/areaPlot/areaplot.png";
import streamplotImg from "../assets/images/graph1.png";
import scatterplotImg from "../assets/images/graph1.png";
export const Trend_EN = {
  id: "cat4",
  title: "Tendance",
  description:`<p>Les graphiques de tendance permettent d'analyser l'évolution des données sur une période donnée. Ils permettent de répondre au questions suivantes : 
  <ul>
  <li>Direction de la tendance : 
  <ul>
  <li>Est-ce que les valeurs augmentent, diminuent ou restent stables au fil du temps ? 
  </li>
    </ul>
  </li>
  <li>Intensité de la tendance :  
  <ul>
  <li>Quelle est l'ampleur du changement sur la période ? </li>
  </ul>
  </li>
  <li>Cycles, saisonnalités et corrélation temporelles :  
  <ul>
  <li>Est-ce qu'il existe des modèles saisonniers ou cycliques dans les données ?</li>
  <li>Est-ce qu'il existe des associations entre des événements spécifiques dans le temps et les fluctuations des données ?</li>
  </ul>
  </li>
  <li>Comparaisons de tendances : 
  <ul>
  <li>Est-ce que les tendances entre les différents groupes, périodes ou régions géographiques sont différentes ? </li>
  </ul>
  </li>
   </ul>
    </p>`,
  img: scatterplotImg,
  imgs: [
    {
      src: scatterplotImg,
      alt: "Line chart",
      title: "Line chart",
      graphId: "graph12",
    },
    {
      src: areaplotImg,
      alt: "Area plot",
      title: "Area plot",
      graphId: "graph1",
    },
    {
      src: streamplotImg,
      alt: "Stream plot",
      title: "Stream plot",
      graphId: "graph13",
    },
  ],
  graphs: ["graph12", "graph1", "graph13"],
};

export const Trend_FR = {
  id: "cat4",
  title: "Tendance",
  description: `<p>Les graphiques de tendance permettent d'analyser l'évolution des données sur une période donnée. Ils permettent de répondre au questions suivantes : 
  <ul>
  <li>Direction de la tendance : 
  <ul>
  <li>Est-ce que les valeurs augmentent, diminuent ou restent stables au fil du temps ? 
  </li>
    </ul>
  </li>
  <li>Intensité de la tendance :  
  <ul>
  <li>Quelle est l'ampleur du changement sur la période ? </li>
  </ul>
  </li>
  <li>Cycles, saisonnalités et corrélation temporelles :  
  <ul>
  <li>Est-ce qu'il existe des modèles saisonniers ou cycliques dans les données ?</li>
  <li>Est-ce qu'il existe des associations entre des événements spécifiques dans le temps et les fluctuations des données ?</li>
  </ul>
  </li>
  <li>Comparaisons de tendances : 
  <ul>
  <li>Est-ce que les tendances entre les différents groupes, périodes ou régions géographiques sont différentes ? </li>
  </ul>
  </li>
   </ul>
    </p>`,
  img: scatterplotImg,
  imgs: [
    {
      src: scatterplotImg,
      alt: "Line chart",
      title: "Line chart",
      graphId: "graph12",
    },
    {
      src: areaplotImg,
      alt: "Area plot",
      title: "Area plot",
      graphId: "graph1",
    },
    {
      src: streamplotImg,
      alt: "Stream plot",
      title: "Stream plot",
      graphId: "graph13",
    },
  ],
  graphs: ["graph12", "graph1", "graph13"],
};
