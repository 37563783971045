import graph2desc from "../assets/images/barplot/barplot_desc.png";
import graph2intro from "../assets/images/barplot/barplot.png";
import graph2ex from "../assets/images/barplot/barplot_exemple.png";
const desc=[{type: 'text', content:`<p>Un <b>diagramme en barres</b> ou  <b> barplot</b> permet de visualiser la relation entre une variables <i>catégorielle</i> comportant plusieurs modalités et une variable <i>numérique</i>.<br/><br/> 
Pour chaque modalité de la variable catégorielle, la valeur numérique associée est représentée par une barre de longueur proportionnelle.</p> `}, {type: 'img', content:graph2desc, alt: 'Graph Bar plot' }]
const ex=[{type: 'text', content: `<h4>Contexte</h4> <p>On souhaite visualiser le nombre de patients admis en psychiatrie dans l’établissement XXX en mai 2023 pour chaque diagnostic principal. L’établissement prend en charge les patients atteint par l’une des 4 pathologies suivantes : <b>anxiété</b>, <b>bipolarité</b>, <b>dépression</b> et <b>schizophrénie</b>.</p><p> Les données sont présentées dans le tableau ci-dessous. </p><br/> 
<table>
<tr><th>diag</th><th>Patients</th></tr>
<tr><td>Dépression</td><td>35</td></tr>
<tr><td>Anxiété</td><td>33</td></tr>
<tr><td>Bipolarité</td><td>52</td></tr>
<tr><td>Schizophrénie</td><td>17</td></tr>
</table>
 <h4>Représentation</h4> <p>Nous sommes ici en présence d’une variable catégorielle représentant les diagnostics (<i>diag</i>) et d’une variable numérique représentant le nombre de patients pour chacune des modalités (<i>patients</i>). La représentation en barplot est alors adapté pour représenter le nombre de patients associés à chacun des diagnostics.</p> <h4>Le graphique suivant est obtenu :</h4>`}, {type: 'img', content: graph2ex, alt:'exemple graph Bar plot'}, {type: 'text', content:`<h4>Interprétation</h4> <p>
Le graphique ci-dessus permet d’identifier rapidement la <b>bipolarité</b> comme étant le diagnostic le plus courant lors des admissions de mai 2023 dans l’établissement XXX avec plus de 50 patients. La <b>schizophrénie</b> est ici la pathologie la moins représentée avec moins de 20 patients. Les nombres de patients admis respectivement pour <b>anxiété</b> et <b>dépression</b> sont proches (légèrement supérieurs à 30).
</p>`}]
const tip=[]
export const Barplot = {
    id: "graph2",
    title: "Barplot",
    description: desc,
    exemple: ex,
    tips: tip,
    img_intro: graph2intro,
    img_desc: graph2desc,
    fonction: `barplot_psy(df, x, y, group = NULL, alpha = 0.5, color = NULL, title = "", subtitle = "", axe_x = "", axe_y = "", fill_title = "", 
    caption = "") `,
  }