import CategoriesPage from "./Categories";
import { styled } from "styled-components";
import { useSelector } from "react-redux";

const IntroP = styled.p`
  padding: 0.8rem;
  font-size: 1.2rem;
  color: var(--secondaryColor);
`;
const Container = styled.div`
  max-width: 960px;
  margin: 0 auto;
  @media screen and (max-device-width: 480px) {
        width: 100%;
        margin-bottom:2em;
}
`;
const IntroDiv = styled.div`
  text-align: center;
  max-width: 960px;
  margin: 0 auto;
`;

export default function HomePage() {
  const lang = useSelector((state) => state.lang.language);
  let introText = "";
  if (lang === "en") {
    introText =
      "An Open-Source Library and Tutorial for Data Visualization in Psychiatry";
  }
  if (lang === "fr") {
    introText = "Introduction francais";
  }
  return (
    <>
      <Container>
        <IntroDiv>
          <IntroP> {introText}</IntroP>
        </IntroDiv>

        <CategoriesPage />
      </Container>
    </>
  );
}
