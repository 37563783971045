import {  NavLink, useParams } from "react-router-dom";
import CategorieComp from "./CategorieComp";
import Classes from "./CategoriesList.module.css";
import { useState } from "react";
import { useSelector } from "react-redux";

export default function CategoriesList({ catList }) {
  const CATEGORIES = useSelector((state) => state.lang.categories);
 
  const [showUl, setShowUl] = useState(false);
  const onClickHandler = () => {
    
    setShowUl(false)
  };
  const id = useParams().catId;
  const toggleUl = ()=>{
  setShowUl(!showUl)
  // console.log(showUl)
  }
  return (
    <>
    
    <div className={!showUl ? Classes.buttonContainer : `${Classes.buttonContainer} ${Classes.change}`} onClick={toggleUl}>
  <div className={Classes.bar1}></div>
  <div className={Classes.bar2}></div>
  <div className={Classes.bar3}></div>

</div>
    <div className={ showUl ? Classes.show : Classes.hide} >
      <ul className={Classes.ul} >
        {catList.map((cat) => {
          if (!id && cat.id === CATEGORIES[0].id ) {
            return (
              <NavLink
                key={cat.id}
                to={`/categories/${cat.id}`}
                onClick={onClickHandler}
                className={Classes.active}
              >
                {" "}
                <li key={cat.id}> {cat.title}</li>{" "}
              </NavLink>
            );
          }
          return (
            <NavLink
              key={cat.id}
              to={`/categories/${cat.id}`}
              onClick={onClickHandler}
              className={({ isActive }) =>
                isActive ? Classes.active : undefined
              }
            >
              <li key={cat.id}> {cat.title}</li>
            </NavLink>
          );
        })}
      </ul>
      </div>
      {!id &&  <CategorieComp />}
    </>
  );
}
