import graph6intro from "../assets/images/densityPlot/densityplot.png";
import graph6desc from "../assets/images/boxplot/box_interpr.png";
import graph6ex1 from "../assets/images/densityPlot/ex_density.png";
import graph6tip1 from "../assets/images/densityPlot/density_ex1.png";
import graph6tip2 from "../assets/images/densityPlot/density_ex2.png";

const desc = [
  {
    type: "text",
    content: `<p>Les density plot permettent de représenter la distribution d'une variable quantitative. L'histogramme et lui partagent le même but, à la différence que le density plot est lissé et l'histogramme a une échelle des abscisses discrète.</p> `,
  },
];
const ex = [
  {
    type: "text",
    content: `<h4>Exemples de cas d'utilisation</h4><ul><li>Nombre d'appels reçu par le 3114 en fonction de l'heure </li> <li>Répartition des âges selon les genres</li>
</ul> <h4>Exemple de graphique</h4>`,
  },
  { type: "img", content: graph6ex1, alt:"Exemple d'un graph Density plot" },
  {
    type: "text",
    content: `<p>Nous constatons que la répartition de l'âge suit une densité normale. Le maximum est atteint pour les femmes à 29 ans à une fréquence de 18% (0.18) environs. Le maximum chez les hommes est atteint vers 33 ans à une fréquence de 14% (0.14) environs.</p>`,
  },
];

const tip = [
  {
    type: "text",
    content: `<ul><li>Si vous voulez observer la distribution de plusieurs modalités d'une variable (exemple : la densité de femmes et la densité d'hommes), si les répartitions sont très similaires, il peut être préférable de choisir plutôt des ridgeline.</li>
<li>De même, s'il y a plus de 3 modalités, à des fins de lisibilité, si les répartitions ne sont pas très différentes, il est préférable d'utiliser des ridgeline.</li>

</ul><p>Exemple : </p>`,
  },
  {
    type: "table",
    content: [
      [
        { type: "text", content: "Density Plot " },
        { type: "text", content: "Ridgeline " },
      ],
      [
        { type: "img", content: graph6tip1, alt:"Graph Density plot" },
        { type: "img", content: graph6tip2, alt:"Graph Ridge line"  },
      ],
    ],
  },
];
export const Densityplot = {
  id: "graph6",
  title: "Density plot",
  description: desc,
  exemple: ex,
  tips: tip,
  img_intro: graph6intro,
  img_desc: graph6desc,
  fonction: `densityplot_psy(df, x,
    group = NULL,
    alpha = 0.5,
    color = NULL,
    title = "",
    subtitle = "",
    axe_x = "", 
    axe_y = "density", 
    fill_title = "",
    caption = ""`,
};
