import graph6ex1 from "../assets/images/divergingBar/ex_graph.png";
import graph6tip from "../assets/images/divergingBar/infographie.png";
const desc = [
  {
    type: "text",
    content: `<p>Le diverging bar plot permet de comparer une valeur par modalité à une valeur seuil sous forme d'écart positif (valeur réelle plus grande que le seuil) ou négatif (valeur réelle plus petit que le seuil). <br/> <br/><b>Ce qui est représenté est l'écart entre la valeur associée à chaque modalité, et le seuil prédéfini. Il n'y a qu'un seul seuil pour toutes les modalités.</b> </p>`,
  },
];
const ex = [
  {
    type: "text",
    content: `<h4>Exemples de cas d'utilisation</h4><ul><li> Ecart du taux d'occupation par service à la valeur seuil de 80%</li>
</ul> <h4>Exemple de graphique</h4>
<p>Ici nous voulons représenter pour chaque service l'écart entre le taux d'occupation des lits réel et la valeur seuil attendue de 80 %.<br/><br/>
Notre table initiale ressemble à celle ci :</p>

<table> <tr><th>Service</th><th>Valeur (en %)</th></tr>
<tr><td>Addictologie</td><td>45</td></tr>
<tr><td>HdJ</td><td>90</td></tr>
<tr><td>Pédopsychiatrie</td><td>85</td></tr>

</table>
<p>Et le graphique renvoyé est celui-ci :</p>
`,
  },
  { type: "img", content: graph6ex1, alt:"Exemple diverging bar" },
  {
    type: "text",
    content: `<h4>Interprétation</h4>
  <p>Nous voyons ici que le service d'addictologie a 35% d'écart avec la valeur de 80% attendue en terme de remplissage de lits.<br/> L'HdJ est a 10% au dessus, et le service de pédopsychiatrie à 5% au dessus.</p>`,
  },
];
const tip = [
  {
    type: "text",
    content: `<ul>
<li>Préférer des écarts sous forme de pourcentage, dans ce cas, ne pas oublier de passer les données du dataframe d'entrée en pourcentage et le seul sous forme de pourcentage</li>
<li>Préciser l'unité de l'écart (pourcent ou absolu)</li>
<li>Vérifier que les données et le seuil ont la même unité (exemple : valeurs en nombre de lits occupés, seuil de 80% de lits occupés)</li>
<li>S'il n'y a qu'une seule valeur à comparer à un seuil, préférer une infographie </li>
</ul>`,
  },
  { type: "img", content: graph6tip, alt: 'infographie' },
  {
    type: "text",
    content:
      "<ul><li>Si vous avez un seuil par modalité, préférer plusieurs infographies. </li></ul>",
  },
];
export const DivergingBar = {
  id: "graph7",
  title: "Diverging Bar",
  description: desc,
  exemple: ex,
  tips: tip,
  img_intro: "",
  img_desc: "",
  fonction: `  divergingbar_psy(df, x, group = NULL, seuil = 0, color = NULL,
    alpha = 0.5, title = "", subtitle = "", axe_x = "",
    axe_y = "", fill_title = "", caption = "")`,
};
