import graph3intro from "../assets/images/boxplot/boxplot.png";
import graph3desc from "../assets/images/boxplot/box_interpr.png";
const desc=[{type: 'text', content: `<p>Le boxplot permet de calculer la médiane et les quartiles d'une variable quantitative pour chaque modalité d'une variable qualitative. Il met en évidence d'éventuelles valeurs aberrantes. </p> `}, {type: 'img', content: graph3desc, alt:'graph Boxplot'}]
const ex=[{type: 'text', content: `<h4>Exemples de cas d'utilisation</h4><ul><li>Examine la distribution des âges (médiane et quartiles) des personnes hospitalisées pour dépression selon la modalité femme/homme </li>
</ul> <h4>Exemple de graphique</h4>`}]
const tip=[]
export const Boxplot= {
    id: "graph3",
    title: "Boxplot",
    description: desc,
    exemple: ex,
    tips: tip,
    img_intro: graph3intro,
    img_desc: graph3desc,
    fonction: `boxplot_psy(df, x, group = NULL, alpha = 0.5, color = NULL, title = "",
    subtitle = "", axe_x = "", axe_y = "", fill_title = "",
    caption = "") `,
  }