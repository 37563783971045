import barplotImg from "../assets/images/barplot/barplot.png";
import divergingbarImg from "../assets/images/divergingBar/ex_graph.png";
import scatterplotImg from "../assets/images/graph1.png";
import bubbleplotImg from "../assets/images/graph1.png";
import VenndiagramImg from "../assets/images/graph1.png";
import correlogramImg from "../assets/images/correlogram/correlogram.png";
import heatmapImg from "../assets/images/graph1.png";
import upsetplotImg from "../assets/images/graph1.png";

export const Relationship_EN = {
  id: "cat3",
  title: "Corrélation ",
  description:`<p>Les graphiques de corrélation explorer les relations entre différentes variables et comment elles interagissent. Ils permettent de répondre aux question suivantes : 
  <ul>
  <li>Relation entre deux variables :
  <ul>
  <li>Est-ce que lorsque l'un des variables change, la seconde change également ?
  </li>
    </ul>
  </li>
  <li>Direction de la relation : 
  <ul>
  <li>Est-ce que les deux variables évoluent dans la même direction ? (quand la première augmente, la seconde augmente également)</li>
  </ul>
  </li>
  <li>Force de la relation : 
  <ul>
  <li>Quelle est la force de la relation entre les deux variables ?</li>
    </ul>
    </li>
   </ul>
    </p>`,
  img: barplotImg,
  imgs: [
    { src: barplotImg, alt: "Bar plot ", title: "Bar plot", graphId: "graph2" },
    {
      src: divergingbarImg,
      alt: "Diverging bar",
      title: "Diverging bar",
      graphId: "graph7",
    },
    {
      src: scatterplotImg,
      alt: "Scatter plot",
      title: "Scatter plot",
      graphId: "graph12",
    },
    {
      src: bubbleplotImg,
      alt: "Bubble plot",
      title: "Bubble plot",
      graphId: "graph4",
    },
    {
      src: VenndiagramImg,
      alt: "Venn diagram",
      title: "Venn diagram",
      graphId: "graph15",
    },
    {
      src: correlogramImg,
      alt: "Correlogram",
      title: "Correlogram",
      graphId: "graph5",
    },
    { src: heatmapImg, alt: "Heatmap", title: "Heatmap", graphId: "graph9" },
    {
      src: upsetplotImg,
      alt: "Upset plot",
      title: "Upset plot",
      graphId: "graph14",
    },
  ],
  graphs: [
    "graph2",
    "graph7",
    "graph12",
    "graph4",
    "graph15",
    "graph5",
    "graph9",
    "graph14",
  ],
};

export const Relationship_FR = {
  id: "cat3",
  title: "Corrélation ",
  description: `<p>Les graphiques de corrélation explorer les relations entre différentes variables et comment elles interagissent. Ils permettent de répondre aux question suivantes : 
  <ul>
  <li>Relation entre deux variables :
  <ul>
  <li>Est-ce que lorsque l'un des variables change, la seconde change également ?
  </li>
    </ul>
  </li>
  <li>Direction de la relation : 
  <ul>
  <li>Est-ce que les deux variables évoluent dans la même direction ? (quand la première augmente, la seconde augmente également)</li>
  </ul>
  </li>
  <li>Force de la relation : 
  <ul>
  <li>Quelle est la force de la relation entre les deux variables ?</li>
    </ul>
    </li>
   </ul>
    </p>`,
  img: barplotImg,
  imgs: [
    { src: barplotImg, alt: "Bar plot ", title: "Bar plot", graphId: "graph2" },
    {
      src: divergingbarImg,
      alt: "Diverging bar",
      title: "Diverging bar",
      graphId: "graph7",
    },
    {
      src: scatterplotImg,
      alt: "Scatter plot",
      title: "Scatter plot",
      graphId: "graph12",
    },
    {
      src: bubbleplotImg,
      alt: "Bubble plot",
      title: "Bubble plot",
      graphId: "graph4",
    },
    {
      src: VenndiagramImg,
      alt: "Venn diagram",
      title: "Venn diagram",
      graphId: "graph15",
    },
    {
      src: correlogramImg,
      alt: "Correlogram",
      title: "Correlogram",
      graphId: "graph5",
    },
    { src: heatmapImg, alt: "Heatmap", title: "Heatmap", graphId: "graph9" },
    {
      src: upsetplotImg,
      alt: "Upset plot",
      title: "Upset plot",
      graphId: "graph14",
    },
  ],
  graphs: [
    "graph2",
    "graph7",
    "graph12",
    "graph4",
    "graph15",
    "graph5",
    "graph9",
    "graph14",
  ],
};
