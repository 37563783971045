import { Outlet } from "react-router-dom";
import CategoriesList from "../components/CategoriesList";
import Classes from "./Categories.module.css";


import { useSelector } from 'react-redux';



export default function CategoriesPage() {
  const list= useSelector(state=> state.lang.categories)
  return (
    <>
      <div className={Classes.container}>
        <CategoriesList catList={list} />
        
        <Outlet />
      </div>
    </>
  );
}
