const desc = [{type: 'text', content: `<p>Le diagramme de Venn permet de visualiser les effectifs de plusieurs groupes. Une observation peut appartenir à un groupe ou à plusieurs. Chaque cercle représente un groupe, une intersection entre deux cercles représente les observations appartenant à ces deux groupes, une intersection entre 3 cercles représente les observations appartenant à ces trois groupes ...
</p>`}]
const ex = [{type: 'text', content: `<h4>Exemples de cas d'utilisation</h4> <ul><li>Association de diagnostics (Anxiété, Depression, Schizophrénie par ex ?)</li></ul><h4>Exemple de graphique</h4>`}]
const tip=[{type:'text', content: `<ul><li> Si il y a plus de 3 groupes, préférer un upset plot
</li> </ul> `}]
export const VennDiagram= {
    id: "graph15",
    title: "Diagramme de Venn",
    description: desc,
    exemple: ex,
    tips: tip,
    img_intro:'' ,
    img_desc: '',
    fonction: ` `,
  }