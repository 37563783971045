const desc = [{type: 'text', content: `<p>Les histogrammes permettent de représenter la distribution d'une variable quantitative. L'histogramme et le density plot partagent le même but, à la différence que le density plot est lissé et l'histogramme a une échelle des abscisses discrète.
</p>`}]
const ex = [{type: 'text', content: `<h4>Exemples de cas d'utilisation</h4> <ul><li>Nombre d'appels reçu par le 3114 par heure</li></ul><h4>Exemple de graphique</h4>`}]
const tip=[{type:'text', content: `<ul><li> Si on veut explorer la relation deux à deux de toutes les variables quantitatives disponibles, il faut utiliser le correlogramme. </li> </ul> `}]
export const Histogram= {
    id: "graph10",
    title: "Histogram",
    description: desc,
    exemple: ex,
    tips: tip,
    img_intro:'' ,
    img_desc: '',
    fonction: ` `,
  }