import densityPlot from "../assets/images/densityPlot/densityplot.png";

const desc = [{type: 'text', content: `<p>Les ridge line permettent de représenter la distribution d'une variable quantitative selon plusieurs modalités d'une variable qualitative. Il est particulièrement indiqué s'il y a plus de 2 modalités à afficher.
</p>`}]
const ex = [{type: 'text', content: `<h4>Exemples de cas d'utilisation</h4> <ul><li>Répartition du nombre d'hospitalisations par diagnostic par age</li></ul><h4>Exemple de graphique</h4>
<table>
<tr><th>Age</th><th>Diagnostic</th><th>n</th></tr>
<tr><td>16 </td><td> Anxiété</td><td> 12</td></tr>
<tr><td> 16</td><td> Dépression</td><td>20 </td></tr>
<tr><td> 16</td><td> Schyzophrénie</td><td>15 </td></tr>
<tr><td> 17</td><td> Anxiété</td><td>16 </td></tr>
<tr><td> 17</td><td> Dépression</td><td> 21</td></tr>
<tr><td>17 </td><td>Schyzophrénie </td><td>17 </td></tr>
<tr><td> 18</td><td> ...</td><td>... </td></tr>


</table><br/>

`}]
const tip=[{type:'text', content: `<ul><li> S'il n'y a qu'une ou deux modalités à représenter, ou si les répartitions sont très clairement distinctes, préférer le densityplot </li> </ul> `}, {type:'img', content:densityPlot, alt:'Graph Density Plot' }]
export const RidgeLine= {
    id: "graph11",
    title: "Ridge line",
    description: desc,
    exemple: ex,
    tips: tip,
    img_intro:'' ,
    img_desc: '',
    fonction: ` `,
  }