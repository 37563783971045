const desc = [{type: 'text', content: `<p>Le Violin plot permet de représenter la distribution d'une variable numérique selon une ou plusieurs modalités. Il est très similaire au boxplot [[Boxplot]] mais permet une meilleure compréhension de la distribution des effectifs grâce à la largeur du graphique.
</p>`}]
const ex = [{type: 'text', content: `<h4>Exemples de cas d'utilisation</h4> <ul></ul><h4>Exemple de graphique</h4>`}]
const tip=[{type:'text', content: `<ul><li>  Il peut être intéressant de cumuler un boxplot avec un violin chart sur un même graphique pour voir la distribution, mais aussi les médianes et quartiles en un coup d'oeil.
</li> </ul> `}]
export const Violinplot= {
    id: "graph16",
    title: "Violin plot",
    description: desc,
    exemple: ex,
    tips: tip,
    img_intro:'' ,
    img_desc: '',
    fonction: ` `,
  }