// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Categories_container__Eu52e{
    margin:0 auto;
    text-align: center;
    border:2px solid var(--secondaryColor);
    width:-moz-fit-content;
    width:fit-content;
    max-width: 960px;
}



`, "",{"version":3,"sources":["webpack://./src/pages/Categories.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,sCAAsC;IACtC,sBAAiB;IAAjB,iBAAiB;IACjB,gBAAgB;AACpB","sourcesContent":[".container{\r\n    margin:0 auto;\r\n    text-align: center;\r\n    border:2px solid var(--secondaryColor);\r\n    width:fit-content;\r\n    max-width: 960px;\r\n}\r\n\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Categories_container__Eu52e`
};
export default ___CSS_LOADER_EXPORT___;
